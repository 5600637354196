import "#css/app-base.css";
import "#css/app-components.css";
import "#css/app-utilities.css";

const main = async () => {
  // Async load dependencies
  const { default: Vue } = await import(/* webpackChunkName: "vue" */ "vue");

  const { default: axios } = await import(
    /* webpackChunkName: "axios" */ "axios"
  );

  return {
    Vue,
    axios,
  };
};

// Execute async function
main().then((components) => {
  const { Vue, axios } = components;

  // Add a global instance of axios to Vue
  axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
  Vue.prototype.$axios = axios;

  /* eslint-disable no-unused-vars */
  const vm = new Vue({
    el: "#app",
    components: {
      HelloWorld: () =>
        import(/* webpackChunkName: "HelloWorld" */ "#vue/HelloWorld.vue"),
      People: () => import(/* webpackChunkName: "People" */ "#vue/People.vue"),
      Tabs: () => import(/* webpackChunkName: "Tabs" */ "#vue/Tabs.vue"),
      JobTabs: () =>
        import(/* webpackChunkName: "JobTabs" */ "#vue/JobTabs.vue"),
      StickyHeader: () =>
        import(/* webpackChunkName: "StickyHeader" */ "#vue/StickyHeader.vue"),
      AnimateNumber: () =>
        import(
          /* webpackChunkName: "AnimateNumber" */ "#vue/AnimateNumber.vue"
        ),
    },
    data: {
      menuIsOpen: false,
    },
    watch: {
      menuIsOpen(open) {
        if (open) {
          document.body.classList.add("mobile-menu-is-open");
        } else {
          document.body.classList.remove("mobile-menu-is-open");
        }
      },
    },
    mounted() {
      window.app.emit("vue-mounted");
    },
    methods: {
      toggleMenu() {
        this.menuIsOpen = !this.menuIsOpen;
      },
      countUp() {},
    },
  });
  /* eslint-enable no-unused-vars */
});
